.custom-shape-divider-bottom-1639670220 {
    position: relative;
    bottom: -250px;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
}

.custom-shape-divider-bottom-1639670220 svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 106px;
}

.custom-shape-divider-bottom-1639670220 .shape-fill {
    fill: #EFFAF9;
}
