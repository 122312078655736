.divider {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
  z-index: 50;
}

.divider svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 67px;
}

.divider .shape-fill {
  fill: #EFFAF9;
}


.arrow{
  animation: arrowMove 2s infinite;
  cursor: pointer;
}

@keyframes arrowMove {
  0% { transform:translateY(0) }
  50% { transform: translateY(15%) }
  100% { transform:translateY(0);}
}

.scrollTop {
  position: fixed; 
  bottom: 30px;
  right: 30px;
  text-align: right;
  z-index: 1000;
  cursor: pointer;
  animation: fadeIn 0.3s;
  transition: transform 0.3s ease;
  color: white;
}

.scrollTop:hover {
  transform: translateY(-8%);
  box-shadow: 0px 12px 10px -7px white;
}

@media (max-width: 600px) {
  .divider {
    display: none;
  }
}